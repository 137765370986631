.logo {
   user-select: none;
   height: fit-content;
   width: fit-content;
   display: flex;
   align-items: flex-end;
}

.logo .icon {
   width: fit-content;
   font-family: cursive;
   font-size: 1em;
   padding: 0.1em 0.3em;
   border-radius: 0.5em;
}

.logo .caption {
   float: left;
   font-size: 0.7em;
   font-family: Verdana, Geneva, Tahoma, sans-serif;
   margin-left: 0.5em;
   margin-bottom: 0.2em;
}
