@import "./variables.scss";

.demo-container {
   overflow-x: hidden;
   overflow-y: scroll;
   display: flex;
   align-items: center;
   justify-content: space-around;
   flex-direction: column;
   gap: 8vh;
   padding: calc(80px + 2vh + 5vh) 0 5vh 0;
}

.convert {
   height: 50px;
   border-radius: 20px;
   font-size: 25px;
   padding: 2vh;
   cursor: pointer;
   border: none;
   background-color: #d43840;
   color: white;
   line-height: 0;
}

.convert:hover {
   // background-color: rgb(252, 81, 95);
   background-color: rgb(231, 68, 76);
}

.convert:active {
   background-color: #d43840;
}

* {
   user-select: none;
}

@media screen and (max-width: $cutOffWidth) {
   .convert {
      display: none;
   }

   p.convert-error {
      display: block;
   }
}
