.goButton {
   padding: 0.3em 0.5em;
   border-radius: 0.5em;
   border: hidden;
   user-select: none;
   cursor: pointer;
}

/* 246d1e */
/* #329126 */
/* 3ea060 */
/* 606C38 */
/* 51A3A3 */

.goButton:hover {
   color: #ffffff;
   /* background-color: #767e5b; */
}

.goButton:active {
   color: #ffffff;
   background-color: #afb4a0;
}
