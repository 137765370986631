.action {
   left: 50%;
   z-index: 2;
   font-size: 1em;
   cursor: pointer;
   padding: 0.4em 0.5em;
   border-radius: 1em;
   margin-left: 0.8em;
}

.action:hover {
   background-color: rgb(223, 223, 223);
}
