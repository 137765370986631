.editor-container {
   position: relative;
}

@mixin editor-layout {
   border-radius: 20px;
   position: absolute;
   width: 100%;
   height: 100%;
}

.editor-mask {
   @include editor-layout;
   background-color: rgba(0, 0, 0, 0);
   z-index: 2;
}

// editor style
.editor {
   @include editor-layout;
   padding: 10px;
   margin: -10px;
   z-index: 1;
   box-shadow: 7px -1px 18px 4px #808080c7;
   animation: editorPadding 1s linear;
}

@keyframes editorPadding {
   from {
      padding: 0px;
      margin: 0px;
   }

   to {
      padding: 10px;
      margin: -10px;
   }
}
