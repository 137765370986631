.header {
   width: 100vw;
   display: grid;
   grid-template-columns: 10fr 1fr;
   justify-content: center;
   align-items: center;
   padding: 5px 0 5px 0;

   div {
      margin: auto;
   }
}

.convert-error {
   grid-column: 3 / 1;
   text-align: center;
   display: none;
}

.write-button {
   font-size: 1.2em;
   font-family: Verdana, Geneva, Tahoma, sans-serif;
   border-radius: 0.7em;
   padding: 0.2em 0.5em;
   border: none;
   width: fit-content;
   vertical-align: middle;
   cursor: pointer;
}
