div.pdfPreview {
   color: white;
   height: 93vh;
   overflow-x: hidden;
   overflow-y: hidden;
   cursor: default;
}

.app-pdf-preview {
   animation: pdfLoading 0.4s linear;
   overflow: hidden;
   height: 93vh;
}

@keyframes pdfLoading {
   from {
      height: 0vh;
   }

   to {
      height: 93vh;
   }
}

.view.right {
   position: relative;
}

.pdf-controls {
   position: absolute;
   width: 100%;
   border-radius: 15px;
   bottom: 0;
   margin-bottom: 5vh;
}

.controls-container {
   width: 150px;
   border-radius: 40px;
   display: flex;
   margin-left: auto;
   margin-right: auto;
   border: 1px;
   box-shadow: 0px 2px 10px -3px;
   user-select: none;
}

.pdf-controls .page-action {
   padding: 8px 15px;
   border-radius: 15px;
   border: none;
   cursor: pointer;
   font-size: 20px;
   font-family: cursive;
}

.page-values {
   width: 80px;
   font-weight: 700;
   display: flex;
   align-items: center;
   justify-content: space-evenly;
   font-weight: bold;
   font-family: fantasy;
}

.page-action:hover {
   background-color: rgba(128, 128, 128, 0.116);
}

.page-action:active {
   background-color: rgba(201, 201, 201, 0.116);
}

.page-action.left:hover {
   border-radius: 20px 0px 0px 20px;
}

.page-action.right:hover {
   border-radius: 0px 20px 20px 0px;
}

.dummy {
   padding: 8px 15px;
   width: 20px;
}
