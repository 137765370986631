// pdf preview

.pdf {
   overflow: hidden;
   box-shadow: 4px 3px 30px 0px #00000087;
   z-index: 4;
   position: relative;
}

.pdf-overlay {
   width: 100%;
   height: 100%;
   position: absolute;
   z-index: 1;
}

.pdf-content {
   animation-duration : 1s;
   animation-name: pdfReveal;
   overflow: hidden;
}

@keyframes pdfReveal {
   from {
      height: 0vh;
   }

   to {
      height: 60vh;
   }
}