
$headerWidth: 6vh;
$topMargin: 7vh;

.grid-container {
   display: grid;
   width: 100vw;
   
   overflow: hidden;

   position : absolute;
   top: $topMargin;
   height : calc(100vh - $topMargin);
   grid-template-rows: $headerWidth calc(100vh - $topMargin - $headerWidth);
   grid-template-columns: auto calc((100vh - $topMargin) * 210 / 297);

   .grid-controls {
      display: flex;
      justify-content: center;
      align-items: center;

      button {
         font-size: 20px;
         cursor: pointer;
      }

      .controls {
         display: flex;
         gap: 15px;
      }
   }

   .grid-pdf-controls {
      gap : 15px
   }
}
