@import "../../Landing/variables.scss";

@mixin transition($time) {
   -webkit-transition: visibility $time ease-in-out, opacity $time ease-in-out;
   -moz-transition: visibility $time ease-in-out, opacity $time ease-in-out;
   -ms-transition: visibility $time ease-in-out, opacity $time ease-in-out;
   -o-transition: visibility $time ease-in-out, opacity $time ease-in-out;
   transition: visibility $time ease-in-out, opacity $time ease-in-out;
}

.window-error {
   color: rgb(143, 14, 14);
   align-items: center;
   text-align: center;
   justify-content: center;
   padding: 20px;
   width: calc(100vw - 40px);
   height: calc(100vh - 40px);
   position: absolute;
   top: 0;
   left: 0;
   z-index: 200;
   overflow: hidden;
   display: flex;
}

.visible {
   visibility: visible;
   opacity: 1;
   @include transition(0.5s);
}

.hidden {
   visibility: hidden;
   opacity: 0;
   @include transition(0.5s);
}

@media screen and (max-width: $cutOffWidth) {
   .window-error.hidden {
      visibility: visible;
      opacity: 1;

      .content h1::before {
         content: "please use bigger screen to experience this site";
      }
   }

   button.button.loaded {
      visibility : visible;
   }
}

.window-error button {
   padding: 15px;
   font-size: 20px;
   border-radius: 15px;
   border: none;
   cursor: pointer;
   visibility: hidden;
}

button.button.error {
   visibility: visible;
}