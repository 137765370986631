$contentHeight: 100vh;
$screenBreak: 1050px;

@use "sass:math";

.demo {
   @media screen and (min-width: 1400px) {
      width: calc(1260px + (100vw - 1400px) * 0.3);
   }

   @media screen and (max-width: 1400px) {
      width: 90vw;
   }
}

.sub-container {
   display: flex;
   flex-direction: row;
   .sub-container-entry {
      width: 50%;
   }
   
   @media screen and (max-width: $screenBreak) {
      flex-direction: column;
      align-items: center;
      .sub-container-entry {
         margin: 20px;
      }
   }

   @media screen and (min-width: 900px) and (max-width: $screenBreak) {
      .sub-container-entry {
         width: 730px;
      }
   }


   @media screen and (max-width: 900px) {
      .sub-container-entry {
         width: 90%;
      }
   }
}

.editor-sub-container {
   @media screen and (max-width: $screenBreak) {
      flex-direction: column-reverse;
   }
}

.pdf-sub-container {
   position: relative;
}

@mixin demoTypo {
   text-align: center;
   display: flex;
   justify-content: center;
   align-items: center;
   font-family: Verdana, Geneva, Tahoma, sans-serif;

   @media screen and (min-width: 550px) {
      font-size: 35px;
   }

   @media screen and (max-width: 550px) {
      font-size: 20px;
   }
}

// description for editor
.typo-1 {
   @include demoTypo;

   p {
      width: 60%;
   }

   .bracket {
      padding: 20px;
      font-size: 80px;
   }
}

.editor-container {
   height: math.div($contentHeight, 2);
}

.pdf {
   height: math.div($contentHeight, 1.6);

   @media screen and (min-width: $screenBreak) {
      position: absolute;
      width: 50%;
      bottom: 0;
   }
}

.typo-2 {
   @include demoTypo;
   flex-direction: column;
   padding: 20px;
}
